const data = [
  {
    id: "1",
    name: "Sushi roll",
    photo: "https://www.aroundweb.it/davidebalice.dev/food/01.jpg",
    description: "Duis dolor esse ut do fugiat et.",
    price: 22.99,
  },
  {
    id: "2",
    name: "Wrap",
    photo: "https://www.aroundweb.it/davidebalice.dev/food/02.jpg",
    description: "Magna elit commodo adipiscing veniam elit sed.",
    price: 16.5,
  },
  {
    id: "3",
    name: "Meals",
    photo: "https://www.aroundweb.it/davidebalice.dev/food/03.jpg",
    description: "Esse enim dolore eiusmod magna tempor dolore.",
    price: 12.99,
  },
  {
    id: "4",
    name: "Burger",
    photo: "https://www.aroundweb.it/davidebalice.dev/food/04.jpg",
    description: "Exercitation nostrud duis aliquip ipsum eiusmod duis.",
    price: 18.99,
  },
  {
    id: "5",
    name: "Salad",
    photo: "https://www.aroundweb.it/davidebalice.dev/food/05.jpg",
    description: "Commodo laboris ipsum esse sit eiusmod ipsum.",
    price: 11.99,
  },
  {
    id: "6",
    name: "Burger",
    photo: "https://www.aroundweb.it/davidebalice.dev/food/06.jpg",
    description: "Enim incididunt magna consectetur consectetur laboris cillum.",
    price: 24.99,
  },
];

export default data;
